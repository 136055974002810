import React, { useEffect, useRef, useState } from 'react';
import Seo from '../components/seo';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

// page definition ----------------------------------------
const DemoMandelbrotPage = () => {
  const mcan = useRef(null);
  const [mhbeg, setMhbeg] = useState(-2);
  const [mhend, setMhend] = useState(1);
  const [mvbeg, setMvbeg] = useState(-1);
  const [mvend, setMvend] = useState(1);
  const [miter, setMiter] = useState(400);
  const [msize, setMsize] = useState('small');
  const canvasWidth = (msize === '' || msize === 'small')? 380: 760;
  const canvasHeight = (msize === '' || msize === 'small')? 280: 560;

  useEffect(() => {
    function mandelIter(cx: number, cy: number, maxIter:number) {
      var x = 0.0;
      var y = 0.0;
      var xx = 0;
      var yy = 0;
      var xy = 0;

      var i = maxIter;
      while (i-- && xx + yy <= 4) {
        xy = x * y;
        xx = x * x;
        yy = y * y;
        x = xx - yy + cx;
        y = xy + xy + cy;
      }
      return maxIter - i;
    }

    function mandelbrot(canvas: HTMLCanvasElement, xmin:number, xmax:number, ymin:number, ymax:number, iterations:number) {
      var width = canvas.width;
      var height = canvas.height;

      var ctx = canvas.getContext('2d');
      var img = ctx?.getImageData(0, 0, width, height);
      var pix = img?.data || [];

      for (var ix = 0; ix < width; ++ix) {
        for (var iy = 0; iy < height; ++iy) {
          var x = xmin + (xmax - xmin) * ix / (width - 1);
          var y = ymin + (ymax - ymin) * iy / (height - 1);
          var i = mandelIter(x, y, iterations);
          var ppos = 4 * (width * iy + ix);

          // default, in the set
          pix[ppos] = 0;
          pix[ppos + 1] = 0;
          pix[ppos + 2] = 16;
          pix[ppos + 3] = 255;

          if (i <= iterations) {
            var c = 3 * Math.log(i) / Math.log(iterations - 1.0);

            if (c < 1) {
              pix[ppos] = 0;
              pix[ppos + 1] = 0;
              pix[ppos + 2] = 255 * c;
            }
            else if ( c < 2 ) {
              pix[ppos] = 0;
              pix[ppos + 1] = 255 * (c - 1);
              pix[ppos + 2] = 255;
            } else {
              pix[ppos] = 255;
              pix[ppos + 1] = 255;
              pix[ppos + 2] = 255 * (c - 2);
            }
          }
        }
      }

      if (img) {
          ctx?.putImageData(img, 0, 0);
      }
    }

    if (mcan.current) {
      console.log("starting mandelbrot");
      mandelbrot(mcan.current, mhbeg, mhend, mvbeg, mvend, miter);
    }
  }, [mhbeg, mhend, mvbeg, mvend, miter, msize])

  // define some preset views
  function setPreset(piter:number, pxbeg:number, pxend:number, pybeg:number, pyend:number) {
    setMiter(piter);
    setMhbeg(pxbeg);
    setMhend(pxend);
    setMvbeg(pybeg);
    setMvend(pyend);
  }
  function backPreset(id: string) {
    switch (id) {
      case "orig":
        setPreset(400, -2, 1, -1, 1);
        break;
      case "jelly":
        setPreset(5000, -0.767253, -0.765223, 0.106788, 0.108141);
        break;
      case "zoom1":
        setPreset(2000, -0.677778, 0.211111, -1.046914, -0.454321);
        break;
      case "whirl-lo":
        setPreset(200, -0.774888, -0.764611, 0.102575, 0.109426);
        break;
      case "whirl-hi":
        setPreset(2400, -0.774888, -0.764611, 0.102575, 0.109426);
        break;
      case "sunny":
        setPreset(2000, -1.246665, -1.246264, 0.094018, 0.094285);
        break;
      case "whirl3":
        setPreset(4000, -0.769826, -0.767796, 0.106457, 0.107811);
        break;
      case "whirl4":
        setPreset(4000, -0.767148, -0.766546, 0.107264, 0.107665);
        break;
      default:
        console.log("unrecognized preset ", id);
        break;
    }
  }

  // adjust max iterations used to see if formula goes to infinity
  function adjustMiter(direction: string) {
      if (direction === "up" && miter < 10000) {
          setMiter(miter+200);
      } else if (direction === "down" && miter > 200) {
          setMiter(miter-200);
      } else {
          console.log("adjustMiter failed ", direction, miter);
      }
  }

  // adjust zoom by compressing or expanding endpt vs begpt
  function adjustZoom(direction:string) {
    const xfactor = (direction === "in") ? 6: -4;
    var dist = Math.abs(mhend - mhbeg)/xfactor;
    setMhbeg(mhbeg + dist);
    setMhend(mhend - dist);

    dist = Math.abs(mvend - mvbeg)/xfactor;
    setMvbeg(mvbeg + dist);
    setMvend(mvend - dist);
  }

  // adjust camera pan by translating start and end
  function adjustPan(direction:string) {
      const afactor = (direction  === "down" || direction === "right")? 0.1 : -0.1;
      if (direction === "up" || direction === "down") {
          let dist = Math.abs(mvend - mvbeg) * afactor;
          setMvbeg(mvbeg + dist);
          setMvend(mvend + dist);
      } else if (direction ===  "left" || direction === "right") {
          let dist = Math.abs(mhend - mhbeg) * afactor;
          setMhbeg(mhbeg + dist);
          setMhend(mhend + dist);
      } else {
          console.log("adjustPan unknown direction ", direction);
      }
  }

  return(
  <>
    <Seo title="Demo - Mandelbrot Set" />
    <h1>Demo - Mandelbrot Set</h1>
    <div style={{ backgroundColor: '#ffffff',
      background: 'linear-gradient(to right,  #F0F8FF, #FAFAFA)'}}>
    <p>
       This html canvas demonstration is an interactive fractal graphic calculated and drawn locally on your
       viewing device.  Zooming in on any of the black edges reveals an increasing complex and sophisticated
       set of patterns.
    </p>
    </div>

   <Box display="flex" flexWrap="wrap" justifyContent="space-between">

   <Box border={1} borderRadius="15px" pb={1} width={canvasWidth}>
     <Box display="flex" justifyContent="space-around" alignItems="baseline">
     <h5>local canvas demo </h5>
     <Button variant="contained" disabled={msize === 'small'} onClick={() => setMsize("small")}>Small</Button>
     <Button variant="contained" disabled={msize === 'medium'} onClick={() => setMsize("medium")}>Medium</Button>
     </Box>
     <canvas ref={mcan} width={canvasWidth} height={canvasHeight} />
     <Box display="flex" justifyContent="center">
       <Tooltip title="zoom in" aria-label="zoom in" arrow>
       <IconButton color="primary" aria-label="zoom in" onClick={() => adjustZoom("in")}>
         <ZoomInIcon />
       </IconButton></Tooltip>

       <Tooltip title="pan up" aria-label="pan up" arrow>
       <IconButton color="primary" aria-label="pan up " onClick={() => adjustPan("up")}>
         <ArrowUpwardIcon />
       </IconButton></Tooltip>

       <Tooltip title="zoom out" aria-label="zoom out" arrow>
       <IconButton color="primary" aria-label="zoom out" onClick={() => adjustZoom("out")}>
         <ZoomOutIcon />
       </IconButton></Tooltip>
     </Box>

     <Box display="flex" flexWrap="wrap" justifyContent="center">
       <Box>
         <Tooltip title="pan left" aria-label="pan left" arrow>
         <IconButton color="primary" aria-label="pan left" onClick={() => adjustPan("left")}>
           <ArrowBackIcon />
         </IconButton>
         </Tooltip>
       </Box>
       <Box>
         X: {mhbeg.toFixed(6)}, {mhend.toFixed(6)} <br /> Y: {mvbeg.toFixed(6)}, {mvend.toFixed(6)} <br />Escape Iterations {miter}
       </Box>
       <Box>
         <Tooltip title="pan right" aria-label="pan right" arrow>
         <IconButton color="primary" aria-label="pan right" onClick={() => adjustPan("right")}>
           <ArrowForwardIcon />
         </IconButton>
         </Tooltip>
       </Box>
     </Box>

     <Box display="flex" justifyContent="center">
       <Tooltip title="More iterations" aria-label="more iterations" arrow>
       <IconButton color="primary" aria-label="more iterations" onClick={() => adjustMiter("up")}>
         <AddCircleIcon />
       </IconButton></Tooltip>

       <Tooltip title="Pan down" aria-label="pan down" arrow>
       <IconButton color="primary" aria-label="pan down" onClick={() => adjustPan("down")}>
         <ArrowDownwardIcon />
       </IconButton></Tooltip>

       <Tooltip title="Fewer iterations" aria-label="less iterations" arrow>
       <IconButton color="primary" aria-label="less iterations" onClick={() => adjustMiter("down")}>
         <RemoveCircleIcon />
       </IconButton></Tooltip>
     </Box>
   </Box>

   <Box width={canvasWidth}>
     <Box mx={1}>
       <i>Interesting points</i>
     </Box>
     <Box mx={1} my={2} display="flex" flexWrap="wrap" justifyContent="space-between">
       <Button color="primary" variant="contained" onClick={() => backPreset("orig")}>Start</Button>
       <Button variant="contained" onClick={() => backPreset("zoom1")}>Top</Button>
       <Button variant="contained" onClick={() => backPreset("sunny")}>Sunny</Button>
       <Button variant="contained" onClick={() => backPreset("jelly")}>Jelly</Button>
     </Box>
     <Box mb={3} mx={1} display="flex" flexWrap="wrap" justifyContent="space-between">
       <Button variant="contained" onClick={() => backPreset("whirl-lo")}>Whirl1</Button>
       <Button variant="contained" onClick={() => backPreset("whirl-hi")}>Whirl2</Button>
       <Button variant="contained" onClick={() => backPreset("whirl3")}>Whirl3</Button>
       <Button variant="contained" onClick={() => backPreset("whirl4")}>#4</Button>
     </Box>
   </Box>
   </Box>


   Curious about the Mandelbrot Set? <br />
   <a href="https://www.youtube.com/watch?v=FFftmWSzgmk" style={{textDecoration: "underline"}}>Ben Sparks - What make the Mandelbrot Set special? (video)</a>
  </>
)}

export default DemoMandelbrotPage
